<template>
  <div class="tab-body-card">
    <!-- Filters Section -->
    <CRow class="mb-4 gap-3">
      <!-- Search Field -->
      <CCol md="4">
        <CInput v-model="searchQuery" placeholder="جستجو بر اساس نام یا شماره تلفن" />
      </CCol>
    </CRow>

    <!-- No Data -->
    <no-data v-if="filteredEmployees.length === 0" :show="true" message="هیچ کارمندی یافت نشد" class="emptyTitle" />

    <!-- Display Farm Employees as Cards -->
    <div v-else>
      <CRow class="farm-employees-list">
        <CCol md="3" class="mb-4" v-for="(employee, index) in filteredEmployees" :key="index">
          <EmployeeCard :employee="employee" />
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import EmployeeCard from "./EmployeeCard.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import NoData from "../../../components/no-data.vue";

export default {
  name: "FarmEmployeesTab",
  components: {
    NoData,
    EmployeeCard,
    vSelect,
  },
  data() {
    return {
      searchQuery: '',
      employeesList: [
        {
          fullName: "سعید ملکی",
          phoneNumber: "09121234567",
          registrationDate: "2023-05-10",
        },
        {
          fullName: "فاطمه رضایی",
          phoneNumber: "09131234567",
          registrationDate: "2023-04-22",
        },
        {
          fullName: "حسن عباسی",
          phoneNumber: "09141234567",
          registrationDate: "2023-06-15",
        },
        {
          fullName: "مینا احمدی",
          phoneNumber: "09151234567",
          registrationDate: "2023-02-08",
        },
        {
          fullName: "علی کاظمی",
          phoneNumber: "09161234567",
          registrationDate: "2023-07-01",
        },
        {
          fullName: "زهرا محمدی",
          phoneNumber: "09171234567",
          registrationDate: "2023-03-20",
        },
      ],
    };
  },
  computed: {
    filteredEmployees() {
      return this.employeesList.filter((employee) => {
        return this.searchQuery === '' ||
          employee.fullName.includes(this.searchQuery) ||
          employee.phoneNumber.includes(this.searchQuery);
      });
    },
  },
};
</script>

<style scoped>

.emptyTitle {
  text-align: center;
  font-size: 1.2rem;
  color: var(--secondary);
}
</style>
