<template>
  <div class="tab-body-card">
    <CRow>
      <!-- Specialists List Section -->
      <CCol md="4" class="specialist-list-section">
        <!-- Search and Filter Section -->
        <CRow class="mb-4 gap-3">
          <!-- Search Field -->
          <CCol md="12">
            <CInput v-model="searchQuery" placeholder="جستجو بر اساس نام یا تخصص" />
          </CCol>

          <!-- Status Filter -->
          <CCol md="12">
            <vSelect
              dir="rtl"
              v-model="selectedStatus"
              :options="statusOptions"
              placeholder="فیلتر بر اساس وضعیت..."
              label="label"
              track-by="value"
            />
          </CCol>
        </CRow>

        <!-- Specialists List -->
        <CRow class="gap-10 specialist-list">
          <CCol md="12" v-for="(specialist, index) in filteredSpecialists" :key="index">
            <SpecialistCard
              :specialist="specialist"
              @highlightOnMap="highlightOnMap"
              @viewDetails="viewSpecialistDetails"
            />
          </CCol>
        </CRow>
      </CCol>

      <!-- Map Section -->
      <CCol md="8" class="position-relative">
        <div id="map" ref="map" class="specialists-map"></div>
      </CCol>
    </CRow>

    <!-- Specialist Details Modal -->
    <VueModal
      :title="'جزئیات ' + currentSpecialist.fullName"
      v-model="specialistModalState"
      wrapper-class="animate__animated animate__faster"
      modal-class="fullscreen-modal"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
    >
      <!-- Specialist details content goes here -->
    </VueModal>
  </div>
</template>

<script>
import SpecialistCard from "./SpecialistCard.vue";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "SpecialistsTab",
  components: {
    SpecialistCard,
    vSelect,
  },
  data() {
    return {
      searchQuery: '',
      selectedStatus: null, // For filtering by status
      statusOptions: [
        { value: '', label: 'همه' },
        { value: 1, label: 'تایید شده' },
        { value: 0, label: 'در حال بررسی' },
      ],
      specialistsList: [
        {
          fullName: "حسین محمدی",
          phoneNumber: "09123456789",
          experience: 5,
          description: "متخصص زراعت",
          status: 1,
          location: [53.123, 35.123],
        },
        {
          fullName: "مریم کریمی",
          phoneNumber: "09123456789",
          experience: 7,
          description: "متخصص کشاورزی",
          status: 0,
          location: [53.321, 35.321],
        },
        {
          fullName: "محمد امیری",
          phoneNumber: "09131234567",
          experience: 10,
          description: "متخصص باغداری",
          status: 1,
          location: [52.321, 34.321],
        },
        {
          fullName: "سارا اکبری",
          phoneNumber: "09131238976",
          experience: 3,
          description: "متخصص خاک‌شناسی",
          status: 0,
          location: [54.123, 34.923],
        },
        {
          fullName: "علی احمدی",
          phoneNumber: "09121234567",
          experience: 8,
          description: "متخصص زراعت",
          status: 1,
          location: [51.321, 35.721],
        },
        {
          fullName: "زهرا رفیعی",
          phoneNumber: "09331234567",
          experience: 6,
          description: "متخصص گیاه‌پزشکی",
          status: 1,
          location: [52.923, 35.321],
        },
        {
          fullName: "حمید کاظمی",
          phoneNumber: "09131234577",
          experience: 12,
          description: "متخصص آبیاری",
          status: 1,
          location: [54.221, 34.123],
        },
      ],
      map: null,
      specialistModalState: false,
      currentSpecialist: {},
    };
  },
  computed: {
    filteredSpecialists() {
      return this.specialistsList.filter((specialist) => {
        const matchesSearch =
          this.searchQuery === '' ||
          specialist.fullName.includes(this.searchQuery) ||
          specialist.description.includes(this.searchQuery);
        const matchesStatus =
          this.selectedStatus === null ||
          this.selectedStatus.value === '' ||
          specialist.status === this.selectedStatus.value;
        return matchesSearch && matchesStatus;
      });
    },
  },
  methods: {
    initMap() {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";

      if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
        mapboxgl.setRTLTextPlugin(
          "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
          null,
          true
        );
      }

      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
        center: [53.126, 33.593],
        zoom: 5,
      });

      this.map.on("load", () => {
        this.showAllSpecialistsOnMap();
      });
    },

    showAllSpecialistsOnMap() {
      this.specialistsList.forEach((specialist) => {
        new mapboxgl.Marker()
          .setLngLat(specialist.location)
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(specialist.fullName))
          .addTo(this.map);
      });
    },

    highlightOnMap(specialist) {
      const [lng, lat] = specialist.location;
      this.map.flyTo({ center: [lng, lat], zoom: 10 });
      new mapboxgl.Marker()
        .setLngLat([lng, lat])
        .addTo(this.map);
    },

    viewSpecialistDetails(specialist) {
      this.currentSpecialist = specialist;
      this.specialistModalState = true;
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>

<style scoped>
.specialists-map {
  position: absolute;
  inset: 0;
}

.specialist-list {
  overflow-y: auto;
  height: 70vh; /* Adjust as needed for scrolling */
}

</style>
